// extracted by mini-css-extract-plugin
var _1 = "SectionHeroSlides___active";
var _2 = "SectionHeroSlides___hero";
var _3 = "SectionHeroSlides___hero-button";
var _4 = "SectionHeroSlides___hero-content";
var _5 = "SectionHeroSlides___hero-image-filter";
var _6 = "SectionHeroSlides___hero-text";
var _7 = "SectionHeroSlides___hero-title";
var _8 = "SectionHeroSlides___hero-wrapper";
var _9 = "SectionHeroSlides___slide";
export { _1 as "active", _2 as "hero", _3 as "heroButton", _4 as "heroContent", _5 as "heroImageFilter", _6 as "heroText", _7 as "heroTitle", _8 as "heroWrapper", _9 as "slide" }
