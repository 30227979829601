// extracted by mini-css-extract-plugin
var _1 = "SectionHeroBasic___button";
var _2 = "SectionHeroBasic___hero";
var _3 = "SectionHeroBasic___hero-title";
var _4 = "SectionHeroBasic___img";
var _5 = "SectionHeroBasic___img-frame";
var _6 = "SectionHeroBasic___img-wrapper";
var _7 = "SectionHeroBasic___section";
var _8 = "SectionHeroBasic___text";
export { _1 as "button", _2 as "hero", _3 as "heroTitle", _4 as "img", _5 as "imgFrame", _6 as "imgWrapper", _7 as "section", _8 as "text" }
